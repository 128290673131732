











import {
  Component,
} from 'vue-property-decorator';
import FormBase from '../FormBase';
import PieChartData from './modelsPie';

// todo: title & icon not in chartData

@Component
export default class FormPie extends FormBase {
  data: PieChartData = {
    title: '',
    description: '',
    icon: '',
    seriesField: '',
    labelsField: '',
  };
}
